import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Solicitud } from '../../../../../interfaces/interface-bakend/ofertas-laborales/solicitudes.interface';
import { MediaService } from '../../../../../services/shared/media.service';
import { GuiMsjService } from '../../../../../services/shared/gui-msj.service';
import { SolicitudesService } from '../../../../../services/ofertas-laborales/solicitudes.service';
import { Router } from '@angular/router';
import { Translators } from '../../../../../services/shared/translators.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
export interface DialogData {
    solicitud: Solicitud;
    adjunto: string;
    label: string;
    numberState: number;
    labelButton: string;
    accept: string; // image/jpeg, image/jpg, image/png, application/pdf
}

@Component({
  selector: 'app-agregar-documentos-extra',
  templateUrl: './agregar-documentos-extra.component.html',
  styleUrls: ['./agregar-documentos-extra.component.scss']
})

export class AgregarDocumentosExtraComponent implements OnInit {

    activarCargaDocumento = false;
    value = 0;
    subscriptionAvance: Subscription;
    flagLoading = false;
    selectedFile: File | null = null;
    previewUrl: SafeUrl | null = null;
    flagDisableButton = true;
    flarCargaCorrecta = false;
    idioma: Subscription;

    constructor(
        private mediaService: MediaService,
        private guiMsjService: GuiMsjService,
        private solicitudesService: SolicitudesService,
        private router: Router,
        private translatorService: Translators,
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef,
        /* Modal Ref. */
        public dialogRef: MatDialogRef<AgregarDocumentosExtraComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData)
        /* Fin Modal Ref. */


        {
            this.idioma = this.translatorService.getIdiomaSeleccionado().subscribe(resp => {
                this.idioma = resp;
              });
            this.mediaService.reiniciarContadorControlUpload();
            this.subscribeAvanceUpload();
        }

    ngOnInit(): void {
    }

    // seleccion de archivo anterior    
    // onFileSelected(event: Event): void {
    //     const input = event.target as HTMLInputElement;
    //     if (input.files && input.files[0]) {
    //         const file = input.files[0];
    //         if (this.mediaService.controlSize(event)) {
    //             this.selectedFile = file;
    //             this.flagDisableButton = false;
    //             this.previewFile(file);
    //         } else {
    //             alert('Archivo demasiado grande, por favor seleccione un archivo de menos de 50MB');
    //         }
    //     }
    // }
    
    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
            const file = input.files[0];
            
            console.log('Archivo seleccionado:', file);
            console.log('Tipo de archivo:', file.type);  // Verifica el tipo de archivo
    
            const fileType = file.type;
    
            // Verifica si el archivo es una imagen y no un PDF u otro tipo de archivo
            if (fileType.startsWith('image/')) {
                console.log('El archivo es una imagen.');
    
                if (this.mediaService.controlSize(event)) {
                    console.log('Iniciando redimensionamiento de imagen...');
    
                    this.resizeImage(file, 1024, 1024, (resizedBlob) => {
                        console.log('Archivo redimensionado:', resizedBlob);
    
                        if (resizedBlob) {
                            this.selectedFile = new File([resizedBlob], file.name, { type: file.type });
                            this.flagDisableButton = false;
                            this.previewFile(this.selectedFile);
                        } else {
                            console.error('Error: No se recibió un Blob redimensionado');
                        }
                    });
                } else {
                    alert('Archivo demasiado grande, por favor selecciona un archivo de menos de 4MB');
                }
    
            } else if (fileType === 'application/pdf') {
                console.log('El archivo es un PDF. No se realizará la redimensionación.');
    
                // Procesa el archivo PDF sin redimensionar
                if (this.mediaService.controlSize(event)) {
                    this.selectedFile = file;
                    this.flagDisableButton = false;
                    this.previewFile(file);
                } else {
                    alert('Archivo demasiado grande, por favor selecciona un archivo de menos de 4MB');
                }
    
            } else {
                console.log('El archivo no es una imagen ni un PDF. No se realizará la redimensionación.');
    
                // Si es otro tipo de archivo (ni imagen ni PDF), procesarlo normalmente
                if (this.mediaService.controlSize(event)) {
                    this.selectedFile = file;
                    this.flagDisableButton = false;
                    this.previewFile(file);
                } else {
                    alert('Archivo demasiado grande, por favor selecciona un archivo de menos de 4MB');
                }
            }
        }
    }

    previewFile(file: File): void {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target?.result) {
                this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(e.target.result as string);
                this.cdr.detectChanges(); // 🔹 Forzar la actualización de la vista
                // console.log('previewFile', this.previewUrl);
            }
        };
        reader.readAsDataURL(file);
    }
    
    async uploadDocumentoAdjunto(selectedFile) {
        let idUsuario;
         
        // controlo si exite la propiedad, porque cuando el backend retorna un objeto no popula en ocaciones.
        if (this.data.solicitud.usuario.hasOwnProperty('_id')) {
         idUsuario = this.data.solicitud.usuario._id; 
        } 
         else { 
            idUsuario = this.data.solicitud.usuario
        }       
         await this.mediaService.uploadOneAttachJobOfferFromWeb(
                selectedFile, idUsuario, 
                this.data.adjunto, this.data.solicitud._id, false
            ).then( async resp => {       
            if (resp['ok']) {
                if (this.value === 100) {
                    this.solicitudesService.setSolicitudSeleccionada(resp['solicitud']);
                    this.flagLoading = false;
                    this.flarCargaCorrecta = true;
                }
            } else {
                this.flarCargaCorrecta = false;
                this.guiMsjService.msjFormSubmit('uploadDocumentERROR');
            }
        });

    }

    subscribeAvanceUpload(){
        this.subscriptionAvance = this.mediaService.getAvance().subscribe(resp => {
            if (resp) {
                this.value = resp.value;
            } else {
                this.value = 0;
                }
        });
    }

    async aceptar(): Promise<void> {
        this.flagDisableButton = true;
        this.flagLoading = true;
        this.mediaService.incrementarContadorControlUpload();
        if (this.mediaService.controlContador()) {
            await this.uploadDocumentoAdjunto(this.selectedFile);
            this.flagLoading = false;
            this.guiMsjService.msjFormSubmit('uploadDocumentOK');
            this.mediaService.reiniciarContadorControlUpload();
            this.dialogRef.close(this.flarCargaCorrecta); // depende de si cargo o no el archivo correctamente
        } else {
            return;
        }
    }

    async closeModal(): Promise<void> {
        this.dialogRef.close(false);
    }
    
    // resize nuevo con compresion de camara del navegador
    resizeImage(file: File, maxWidth: number, maxHeight: number, callback: (result: Blob) => void) {
        const reader = new FileReader();
    
        reader.onload = (event) => {
            const img = new Image();
            img.src = (event.target as FileReader).result as string;
    
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    let width = img.width;
                    let height = img.height;
    
                    // Ajusta dimensiones
                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }
    
                    canvas.width = width;
                    canvas.height = height;
    
                    // Dibuja la imagen redimensionada
                    ctx.drawImage(img, 0, 0, width, height);
    
                    // Convertir el canvas a Blob
                    canvas.toBlob((blob) => {
                        if (blob) {
                            console.log('Blob redimensionado creado con tamaño:', blob.size);
                            callback(blob);
                        } else {
                            console.error('Error: No se pudo crear el Blob');
                        }
                    }, file.type, 0.8); // Calidad del 80%
    
                    // Libera recursos
                    img.src = '';
                    canvas.width = 0;
                    canvas.height = 0;
                } else {
                    console.error('Error: No se pudo obtener el contexto del canvas');
                }
            };
    
            img.onerror = () => console.error('Error: No se pudo cargar la imagen');
        };
    
        reader.onerror = () => console.error('Error: No se pudo leer el archivo');
        reader.readAsDataURL(file);
    }


}
