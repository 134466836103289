import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { Translators } from './translators.service';
import { TranslatePipe } from '../../pipes/translate.pipe';
@Injectable({
    providedIn: 'root',
})
export class GuiMsjService {
    idioma: Subscription;
    msjPrincipal: string;
    msjSecundario: string;
    msjBtn: string;

    constructor(
        private translatorService: Translators,
        private translatePipe: TranslatePipe,
    ) {
        // this.idioma = this.translatorService
        //     .getIdiomaSeleccionado()
        //     .subscribe((resp) => {
        //         this.idioma = resp;
        //     });
    }

    async msjFormSubmit(type: string, message?: string): Promise<void> {
        switch (type) {
            case 'datosInvalidosTokenLogin':
                this.msjPrincipal = "Por favor, inténtelo nuevamente.";
                const subtitulo1 = "Parece que su conexión a internet es lenta. Por favor, inténtelo de nuevo más tarde - Login";
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: this.msjPrincipal,
                    text: subtitulo1,
                    showConfirmButton: true, // Cambiar a true para mostrar el botón
                    confirmButtonText: 'Aceptar', // Texto del botón
                });
                break;
            case 'datosInvalidosToken':
                this.msjPrincipal = "Por favor, inténtelo nuevamente.";
                const subtitulo2 = "Parece que su conexión a internet es lenta. Por favor, inténtelo de nuevo más tarde.";
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: this.msjPrincipal,
                    text: subtitulo2,
                    showConfirmButton: true, // Cambiar a true para mostrar el botón
                    confirmButtonText: 'Aceptar', // Texto del botón
                });
                break;
            case 'tokenVencido':
                this.msjPrincipal = "La sesion ha expirado";
                const subtitulo3 = "Por favor, vuelva a ingresar.";
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: this.msjPrincipal,
                    text: subtitulo3,
                    showConfirmButton: true, // Cambiar a true para mostrar el botón
                    confirmButtonText: 'Aceptar', // Texto del botón
                });
                break;
            case 'loginOk':
                this.msjPrincipal = "Usuario logueado correctamente";
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: this.msjPrincipal,
                    showConfirmButton: false,
                    timer: 2000,
                });
                break;

            case 'loginError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "El email indicado no está verificado o el usuario no existe, por favor intente nuevamente";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'createUserError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "Por favor contacte atención al cliente"
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'createAfiliadoError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "Por favor contacte atención al cliente";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'duplicateAccount':
                this.msjPrincipal = "El email ya se encuentra registrado";
                this.msjSecundario = "Por favor intente con uno diferente";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'createUserOk':
                this.msjSecundario = "Su email fue registrado exitosamente. Ha sido enviado un PIN de activación. Revise su bandeja de entrada.";
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'createAfiliadoOk':
                this.msjSecundario = "El registro manual del correo electrónico del afiliado se ha completado con éxito.";
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'resetPassword':
                this.msjSecundario = "Contraseña recuperada exitosamente";
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'resetPasswordError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "Email no existente";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'activateAccount':
                this.msjPrincipal = "Cuenta activada exitosamente";
                this.msjSecundario = "Ingrese con  su usuario y clave...";
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: this.msjPrincipal,
                    text: this.msjSecundario,
                    showConfirmButton: true, // Cambiar a true para mostrar el botón
                    confirmButtonText: 'Aceptar', // Texto del botón
                });
                break;

            case 'activateAccountError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "El PIN ingresado o email, es incorrecto";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'pinResendOk':
                this.msjSecundario = "Un PIN de activación ha sido enviado a su email. Revise su bandeja de entrada.";
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'pinResendError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "Su cuenta ya ha sido verificada";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'errorTokenLogon':
                this.msjPrincipal = "Error";
                this.msjSecundario = "No autorizado";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'newPasswordOk':
                this.msjSecundario = "Contraseña actualizada exitosamente";
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'newPasswordError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "Por favor contacte atención al cliente";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'formularioOfertaCargado':
                this.msjPrincipal = "Formulario";
                this.msjSecundario = "Cargado";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            /* MSJ Entidades */
            case 'planDuplicado':
                this.msjPrincipal = "Plan";
                this.msjSecundario = "Duplicado, ya existe este plan";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'empresaDuplicada':
                this.msjPrincipal = "Empresa";
                this.msjSecundario = "Duplicada, ya existe esta empresa";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'sedeDuplicada':
                this.msjPrincipal = "Sede";
                this.msjSecundario = "Duplicada, ya existe esta sede";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'doctorDuplicado':
                this.msjPrincipal = "Doctor";
                this.msjSecundario = "Duplicado, ya existe este doctor";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'localidadDuplicada':
                this.msjPrincipal = "Localidad";
                this.msjSecundario = "Duplicada, ya existe esta localidad";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'centroMedicoDuplicado':
                this.msjPrincipal = "Centro Médico";
                this.msjSecundario = "Duplicado, ya existe este centro médico";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'afiliadoDuplicado':
                this.msjPrincipal = "Afiliado";
                this.msjSecundario = "El afiliado que intenta actualizar ya está registrado en el sistema.";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

                /* MSJ Termina Entidades */

            case 'emailDuplicado':
                this.msjPrincipal = "Email";
                this.msjSecundario = "Duplicado, ya existe el email ingresado";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'formularioCargado':
                this.msjPrincipal = "Enviado";
                this.msjSecundario = "formulario enviado correctamente"

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'uploadDocumentOK':
                this.msjPrincipal = "Procesado";
                this.msjSecundario = "El documento fue cargado correctamente";

                Swal.fire(
                    this.msjPrincipal,
                    this.msjSecundario,
                    'success'
                ).then(() => {});
                break;

            case 'uploadDocumentERROR':
                this.msjPrincipal = "¡Atención!";
                this.msjSecundario = "Se produjo un error al procesar los documentos";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'documentosEnviadosOK':
                this.msjPrincipal = "Procesado";
                this.msjSecundario = "Documentos enviados correctamente";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'documentosEnviadosERROR':
                this.msjPrincipal = "¡Atención!";
                this.msjSecundario = "Se produjo un error al procesar los documentos";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'solicitudDocCompleta':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "Toda la documentación se encuentra adjunta";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'solicitudDocCompleta':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "Documentación completa";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'solicitudEntregadaDocumentosSubidos':
                this.msjPrincipal = "Solicitud Generada y Documentos Entregados";
                this.msjSecundario = "El estado de su solicitud se ha actualizado con éxito. Será redirigido al menú principal en breve.";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'solicitudEstadoOK':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "Estado de la solicitud actualizado exitosamente";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'solicitudEstadoError':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "Se produjo un error al cambiar de estado la solicitud";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'envioEmailSolicitarPagoOK':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "Se procesó y envió la solicitud de pago total, de forma correcta";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'envioEmailSolicitarPagoError':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "Se produjo un error al enviar solicitud de pago";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'crearSolicitudOK':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "Enviada correctamente";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearSolicitudError':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "Error al crear la solicitud en el sistema";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;

            case 'crearPlanOK':
                this.msjPrincipal = "Plan";
                this.msjSecundario = "Creado correctamente"

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearPlanError':
                this.msjPrincipal = "Plan";
                this.msjSecundario = "Error al crear el plan en el sistema";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;

            case 'crearEmpresaOK':
                this.msjPrincipal = "Empresa";
                this.msjSecundario = "Creada correctamente";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'modificarEmpresaOK':
            this.msjPrincipal = "Empresa";
            this.msjSecundario = "Modificada correctamente";

            Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

            break;

            case 'crearEmpresaError':
                this.msjPrincipal = "Empresa";
                this.msjSecundario = "Error al crear la empresa en el sistema";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;

            case 'modificarEmpresaError':
                this.msjPrincipal = "Empresa";
                this.msjSecundario = "Error al modificar la empresa en el sistema";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;

            case 'crearSedeOK':
                this.msjPrincipal = "Sede";
                this.msjSecundario = "Creada correctamente";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearSedeError':
                this.msjPrincipal = "Sede";
                this.msjSecundario = "Error al crear la sede en el sistema";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;
            case 'crearDoctorOK':
                this.msjPrincipal = "Doctor";
                this.msjSecundario = "Creado correctamente";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearDoctorError':
                this.msjPrincipal = "Doctor";
                this.msjSecundario = "Error al crear el doctor en el sistema";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;
            case 'crearLocalidadOK':
                this.msjPrincipal = "Localidad";
                this.msjSecundario = "Creada correctamente";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearLocalidadError':
                this.msjPrincipal = "Localidad";
                this.msjSecundario = "Error al crear la localidad en el sistema";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;
            case 'crearCentroMedicoOK':
                this.msjPrincipal = "Centro Médico";
                this.msjSecundario = "Creado correctamente";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');

                break;

            case 'crearCentroMedicoError':
                this.msjPrincipal = "Centro Médico";
                this.msjSecundario = "Error al crear el centro médico en el sistema";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');

                break;

            case 'pagoSolicitudOK':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "De pago procesada correctamente";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'pagoSolicitudError':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "Se produjo un error al procesar solicitud de pago";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'UploadError':
                this.msjPrincipal = "Carga de archivo";
                this.msjSecundario = "Se produjo un error al procesar el archivo";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'pagarSaldoOK':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "De pago procesada correctamente";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'pagarSaldoCursosModulosOK':
                this.msjPrincipal = 'Procesado Correctamente';
                this.msjSecundario = 'Cursos/módulos ya disponibles';

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'pagarSaldoError':
                this.msjPrincipal = "Solicitud";
                this.msjSecundario = "Se produjo un error al procesar solicitud de pago";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            /* CURSOS */
            case 'pagoCursoOK':
                this.msjPrincipal = "Curso";
                this.msjSecundario = "Gracias por realizar el pago, ya tienes el curso disponible";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'pagoCursoError':
                this.msjPrincipal = "Curso";
                this.msjSecundario = "Se produjo un error al procesar solicitud de pago";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'ProfesorCreadoError':
                this.msjPrincipal = 'Error';
                this.msjSecundario = 'No se ha dado de alta al profesor...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            /* PAYPAL */
            case 'paypalOK':
                this.msjPrincipal = "PAGO EXITOSO";
                this.msjSecundario = "Su pago fue procesado correctamente por Paypal";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'paypalError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "Se produjo un error en el proceso de pago. Pago no procesado.";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'NotifyServiceFailed':
                this.msjPrincipal = "Error";
                this.msjSecundario = "Al registrar el pago en el sistema";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;


            /* AFILIADO - FAMILIARES */
            case 'afiliadoModificadoOK':
                this.msjPrincipal = "Afiliado Modificado";
                this.msjSecundario = "cambios procesados...";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'afiliadoModificadoError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "Se produjo un error al modificar el afiliado";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'SinFamiliares':
                this.msjPrincipal = "Sin Familiares";
                this.msjSecundario = "El afiliado no tiene familiares a cargo en este momento.";

                Swal.fire(this.msjPrincipal, this.msjSecundario, 'info');
                break;

            /* Cursos administracion */
            case 'configGuardadaOK':
                this.msjPrincipal = 'Guardado';
                this.msjSecundario = 'configuración correcta';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'configGuardadaERROR':
                this.msjPrincipal = 'Error';
                this.msjSecundario =
                    'la configuración general no pudo ser guardada';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'configGuardadaServiceERROR':
                this.msjPrincipal = 'Error';
                this.msjSecundario =
                    'la configuración general no pudo ser guardada a nivel de servicio...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'configGuardadaServiceERROR':
                this.msjPrincipal = 'Error';
                this.msjSecundario =
                    'no se puedo asignar el curso al alumno...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            /* Administracion de usuarios */
            case 'createManulUserOk':
                this.msjSecundario = "Su email fue registrado exitosamente. Ha sido enviado un PIN de activación. Revise su bandeja de entrada.";
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'createManualUserError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "El usuario NO fue registrado";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'errorSendMailRegistroError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "No se pudo enviar el email de registro, el email no es válido";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'UsuarioModificadoOK':
                this.msjSecundario = "Datos guardados exitosamente";
                Swal.fire('Modificado', this.msjSecundario, 'success');
                break;

            case 'UsuarioModificadoError':
                this.msjPrincipal = "Error";
                this.msjSecundario = "Error al guardar";
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            /* Examenes */
            case 'modificarRespuesta':
                this.msjSecundario =
                    'Puede modificar la respuesta, pero debera cargar todas las opciones nuevamente..';
                Swal.fire('ATENCIÓN', this.msjSecundario, 'info');
                break;

            case 'modificarRespuestaMultimedia':
                this.msjSecundario =
                    'No Puede modificar la respuesta, debe eliminarla (una o más respuestas contienen multimedia)..';
                Swal.fire('ATENCIÓN', this.msjSecundario, 'info');
                break;

            case 'respuestasCargadas':
                this.msjPrincipal = 'Respuestas cargadas y Examen configurado';
                this.msjSecundario =
                    'Su examen fue generado de forma correcta.';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'recuperatorioConfig':
                this.msjPrincipal = 'Control configuración Recuperatorio';
                this.msjSecundario =
                    'El campo Porcentaje Min. debe ser 60% y Nota mínima - Cantidad Recuperatorio deben esta cargados..';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'recuperatorioFechas':
                this.msjPrincipal = 'Control fechas recuperatorio';
                this.msjSecundario =
                    'Debe introducir las fechas del recuperatorio.';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'recuperatorioCant':
                this.msjPrincipal = 'Control de recuperatorio';
                this.msjSecundario =
                    'Debe definir la cantidad de recuperatorios.';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'respuestasConfiguradas':
                this.msjPrincipal = 'Respuestas configuradas';
                this.msjSecundario =
                    'No puede agregar más respuestas, debido al tipo de pregunta...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'agregarConcepto':
                this.msjPrincipal = 'ATENCIÓN';
                this.msjSecundario =
                    'Debe agregar al menos un concepto, para agregar opciones (columnas)...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'maxOpciones':
                this.msjPrincipal = 'ATENCIÓN';
                this.msjSecundario = 'Las opciones son limitadas...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'ingrseTipoPregunta':
                this.msjPrincipal = 'ATENCIÓN';
                this.msjSecundario = 'seleccione un tipo de pregunta...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'warning');
                break;

            case 'examenAsignado':
                this.msjPrincipal = 'Examen Asignado';
                this.msjSecundario = 'el examen fue asignado correctamente...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            case 'examenAsignadoError':
                this.msjPrincipal = 'ATENCIÓN';
                this.msjSecundario = 'el examen fue asignado correctamente...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'error');
                break;

            case 'finalizarAsignacionExamen':
                this.msjPrincipal = 'FINALIZADO';
                this.msjSecundario =
                    'el examen fue creado y asignado correctamente...';
                Swal.fire(this.msjPrincipal, this.msjSecundario, 'success');
                break;

            /* Mensajes Genéricos */
            case 'Espere':
                this.msjSecundario = "Por favor espere...";
                Swal.fire('', this.msjSecundario, 'info');
                break;

            case 'Guardando':
                this.msjSecundario = "Guardando...";
                Swal.fire({
                    allowOutsideClick: false,
                    icon: 'info',
                    text: this.msjSecundario,
                });
                Swal.showLoading();
                break;

            case 'DatosGuardados':
                this.msjSecundario = "Datos guardados exitosamente";
                Swal.fire({
                    icon: 'success',
                    title: this.msjSecundario,
                    showConfirmButton: false,
                    timer: 1100,
                });
                break;

            /* Formulario de contacto */

            case 'ContactoEnviado':
                this.msjSecundario = "Su formulario ha sido enviado con éxito";
                Swal.fire('', this.msjSecundario, 'info');
                break;

            case 'ErrorContacto':
                this.msjSecundario = "Error al enviar su formulario, intente nuevamente";
                Swal.fire('', this.msjSecundario, 'error');
                break;

            /* fin formulario contacto */
            case 'GuardarError':
                this.msjSecundario = "Error al guardar";
                Swal.fire('', this.msjSecundario, 'error');
                break;

            case 'Guardando':
                this.msjSecundario = "Guardando...";
                Swal.fire('', this.msjSecundario, 'info');
                break;

            /* PERMISO RUTA DENEGADO*/
            case 'SinPermisoParaUrl':
                this.msjSecundario = "No tiene los permisos suficientes";
                Swal.fire({

                    title: this.msjSecundario,
                    html: `El rol <strong>${message}</strong> no tiene permisos para acceder a esta información`,
                    icon: 'info',
                    backdrop: `rgba(230,230,230,0.9)`,
                    showCloseButton: true,
                })
                break;

            /* COMPROBANTES*/
            case 'comprobanteCreadoOK':
                this.msjSecundario = "Guardado";
                Swal.fire('', this.msjSecundario, 'success');
                break;

            case 'comprobanteCreadoERROR':
                this.msjSecundario = "Error al guardar";
                Swal.fire('', this.msjSecundario, 'warning');
                break;

            /* INVITADO */
            case 'invitado':
                this.msjSecundario = "Debe registrarse o Iniciar sesión";
                Swal.fire('', this.msjSecundario, 'warning');
                break;

            /* GRILLA */
            case 'grillaSinDatos':
                this.msjSecundario = 'No hay datos para la selección..';
                Swal.fire('', this.msjSecundario, 'warning');
                break;

            default:
                break;
        }
    }

    async msjConfirmCancel(): Promise<boolean> {
        this.msjPrincipal = "¿Está seguro de que desea autorizar el siguiente trámite?";
        this.msjSecundario = "¡No podrá volver atrás!";
        this.msjBtn = "Autorizar";

        return new Promise<boolean>((resolve) => {
            Swal.fire({
                title: this.msjPrincipal,
                text: this.msjSecundario,
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.msjBtn,
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    async translatePipeFunction(value, pMsj) {
        const msj = await this.translatePipe.transform(value, pMsj);
        return msj;
    }

    async msjBackend(msj: string, msj2?: string) {
        Swal.fire({
            position: 'center',
            icon: 'warning',
            title: msj || 'Error General contacte administración',
            text: msj2 || '',
            showConfirmButton: true,
        });
    }
    
}
