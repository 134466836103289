import { Component, OnInit } from '@angular/core';
import { DetectarDispositivoService } from '@services/detectar-dispositivo/detectar-dispositivo.service';

@Component({
  selector: 'app-appstore-badges',
  templateUrl: './appstore-badges.component.html',
  styleUrls: ['./appstore-badges.component.scss']
})
export class AppstoreBadgesComponent implements OnInit {

  isAndroid: boolean = false;
  isIOS: boolean = false;

  constructor(private detectarDispositivoService: DetectarDispositivoService) { }

  ngOnInit(): void {
    this.isAndroid = this.detectarDispositivoService.isAndroid();
    this.isIOS = this.detectarDispositivoService.isIOS();
  }

  redirectTo(platform: string): void {
    if (platform === 'android') {
      window.open('https://play.google.com');
    } else if (platform === 'ios') {
      window.open('https://www.apple.com/app-store/');
    }
  }

}
