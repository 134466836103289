<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/dashboard">Inicio</a></li>
                <li><a routerLink="/tramites-listado">Listado de Trámites</a></li>
                <li>Modificar Trámite</li>
            </ul>
            <h2>Modificar Trámite</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape-signomedico.png" width="10%" alt="image"></div>
</div>

<app-loading *ngIf="!flagLoaded" class="m-5"></app-loading><br>

<div class="andorra-apply-content ptb-70  animate__animated animate__fadeIn">
    <div class="container">
        <div class="row mx-auto">
            <div class="col-lg-12 col-md-12">
                <div class="apply-form" *ngIf="actividad">
                    <form [formGroup]="form" (ngSubmit)="put()">

                        <div class="row">

                            <div class="col-lg-6 col-md-6 col-xs-12">

                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Nombre</mat-label>
                                        <input formControlName="nombre" [class.is-invalid]="nombreNoValido" matInput placeholder="Nombre del trámite" autocomplete="off">
                                        <small *ngIf="nombreNoValido" class="text-danger">Ingrese nombre del trámite</small>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Pais</mat-label>
                                        <mat-select formControlName="pais" [class.is-invalid]="paisNoValido">
                                            <mat-option *ngFor="let item of paisesDisponibles" value="{{ item._id }}">{{ item.pais.name_es }}</mat-option>
                                        </mat-select>
                                        <small *ngIf="paisNoValido" class="text-danger">Seleccione un país disponible</small>
                                    </mat-form-field>
                                </div>

                                <!-- <div class="form-group" hidden>
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Adjuntos</mat-label>
                                        <mat-select formControlName="adjuntos" multiple [class.is-invalid]="adjuntoNoValido">
                                            <mat-option *ngFor="let item of adjuntos" value="{{ item._id }}">{{ item.nombre }}</mat-option>
                                        </mat-select>
                                        <small *ngIf="adjuntoNoValido" class="text-danger">Seleccione un adjunto</small>
                                    </mat-form-field>
                                    <mat-card *ngIf="this.actividad.adjuntos.length > 0" class="animate__animated animate__fadeIn">
                                        <mat-card-title>Documentos adjuntos</mat-card-title>
                                        <mat-card-subtitle>para modificar los adjuntos, debe seleccionar nuevamente todos los documentos</mat-card-subtitle>
                                        <mat-card-content>
                                            <h5 *ngFor="let item of this.actividad.adjuntos">{{ item.nombre }} </h5>
                                        </mat-card-content>
                                    </mat-card>
                                </div> -->


                                <!-- <div class="form-group">
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Módulos</mat-label>
                                        <mat-select formControlName="cursoModulo" tabindex="5" multiple [class.is-invalid]="cursoModuloNoValido">
                                            <mat-option *ngFor="let item of cursosModulos" value="{{ item._id }}">{{ item.nombre }}</mat-option>
                                        </mat-select>
                                        <small *ngIf="cursoModuloNoValido" class="text-danger">Seleccione un módulo disponible</small>
                                    </mat-form-field>

                                    <mat-card *ngIf="this.actividad.cursos.length > 0" class="animate__animated animate__fadeIn">
                                        <mat-card-title>Módulos</mat-card-title>
                                        <mat-card-subtitle>para modificar los adjuntos, debe seleccionar nuevamente todos los documentos</mat-card-subtitle>
                                        <mat-card-content>
                                            <h5 *ngFor="let item of this.actividad.cursos">{{ item.nombre }} </h5>
                                        </mat-card-content>
                                    </mat-card>
                                </div> -->

                                <div class="row">

                                    <div class="col-md-6">

                                        <div class="form-group">
                                            <mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                                                <mat-label>Vigencia desde</mat-label>
                                                <input formControlName="vigenciaDesde" matInput [matDatepicker]="picker3" [class.is-invalid]="vigenciaNoValida" autocomplete="off">
                                                <small *ngIf="vigenciaNoValida" class="text-danger">Ingrese fecha desde</small>
                                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                                <mat-datepicker #picker3 color="primary"></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                    </div>

                                    <div class="col-md-6">

                                        <div class="form-group">
                                            <mat-form-field color="accent" appearance="fill" [style.width.%]=100>
                                                <mat-label>Vigencia hasta</mat-label>
                                                <input formControlName="vigenciaHasta" matInput [matDatepicker]="picker4" [class.is-invalid]="vigenciaNoValidaHasta" autocomplete="off">
                                                <small *ngIf="vigenciaNoValidaHasta" class="text-danger">Ingrese fecha hasta</small>
                                                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                                <mat-datepicker #picker4 color="primary"></mat-datepicker>
                                            </mat-form-field>
                                        </div>

                                    </div>

                                </div>

                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Descripción (visible en la aplicación móvil)</mat-label>
                                        <textarea formControlName="descripcion" [class.is-invalid]="descripcionNoValida" rows="7" matInput placeholder="Descripción"></textarea>
                                        <small *ngIf="descripcionNoValida" class="text-danger">Ingrese una descripción</small>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Label Resumen</mat-label>
                                        <input type="text" formControlName="resumenAvatar" matInput placeholder="Texto label resumen" autocomplete="off">
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Documentación requerida (visible en la aplicación móvil)</mat-label>
                                        <textarea formControlName="documentacionRequerida" [class.is-invalid]="documentacionNoValida" matInput rows="7" placeholder="Documentación requerida para el trámite"></textarea>
                                        <small *ngIf="documentacionNoValida" class="text-danger">Ingrese la documentación requerida</small>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Importante</mat-label>
                                        <textarea formControlName="importante" [class.is-invalid]="importanteNoValido" matInput rows="7" placeholder="Información importante del trámite"></textarea>
                                        <small *ngIf="importanteNoValido" class="text-danger">Ingrese Información imporante</small>

                                    </mat-form-field>
                                </div>

                                <!--<div class="caja">-->
                                <!--</div>-->

                                <div class="form-group">
                                    <mat-label>Imagen portada</mat-label>
                                    <input [disabled]="condition" class="file-input" type="file" formControlName="imagen" #fileInput accept="image/jpeg, image/jpg, image/png" (change)="onChangeFile(fileInput.files[0], $event)" />
                                    <mat-divider></mat-divider>
                                    <div class="material-ripple-container mat-elevation-z4 mt-5" matRipple [matRippleCentered]="centered" [matRippleDisabled]="disabled" [matRippleUnbounded]="unbounded" [matRippleRadius]="radius" [matRippleColor]="color">

                                        <ng-container *ngIf="this.file; else elseTemplate">

                                            <img [src]="imageUrl" />

                                        </ng-container>
                                        <ng-template #elseTemplate>

                                            <img *ngIf="this.actividad" [src]=" 1 | mediaOfertaLaboral: this.actividad.imagen  | safe" class="rounded float-start" [alt]="this.actividad.imagen" width="300px">

                                        </ng-template>

                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12">

                                <div class="form-group" hidden>
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Tipo puesto</mat-label>
                                        <mat-select formControlName="tipoPuesto">
                                            <mat-option *ngFor="let item of tipoPuestos" value="{{ item._id }}">{{ item.concepto }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="form-group" hidden>
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Área</mat-label>
                                        <mat-select formControlName="area">
                                            <mat-option *ngFor="let item of areas" value="{{ item._id }}">{{ item.concepto }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="form-group" hidden>
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Modalidad</mat-label>
                                        <mat-select formControlName="modalidad">
                                            <mat-option *ngFor="let item of modalidades" value="{{ item._id }}">{{ item.concepto }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="form-group" hidden>
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Sueldo $</mat-label>
                                        <input type="number" formControlName="sueldo" [class.is-invalid]="sueldoNoValido" matInput placeholder="Sueldo de la pre inscrpción" autocomplete="off">
                                        <small *ngIf="sueldoNoValido" class="text-danger">Ingrese el sueldo</small>
                                    </mat-form-field>
                                </div>

                                <div class="form-group" hidden>
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Circuito</mat-label>
                                        <mat-select formControlName="circuito">
                                            <mat-option *ngFor="let item of this.circuitos" value="{{ item.circuito }}">{{ item.viewValue }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="form-group" hidden>
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Costo administrativo $</mat-label>
                                        <input type="number" formControlName="costoAdministrativo" [class.is-invalid]="costoAdministrativoNoValido" matInput placeholder="Costo administrativo de la pre inscrpción" autocomplete="off">
                                        <small *ngIf="costoPostAprobacionNoValido" class="text-danger">Ingrese el costo administrativo</small>
                                    </mat-form-field>
                                </div>

                                <div class="form-group" hidden>
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Costo post aprobación $</mat-label>
                                        <input type="number" formControlName="costoPostAprobacion" [class.is-invalid]="costoPostAprobacionNoValido" matInput placeholder="Costo post aprobación de la pre inscrpción" autocomplete="off">
                                        <small *ngIf="costoPostAprobacionNoValido" class="text-danger">Ingrese el costo post aprobación</small>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Detalle</mat-label>
                                        <textarea formControlName="detalle" [class.is-invalid]="detalleNoValido" matInput rows="7" placeholder="Detalle de la pre inscrpción"></textarea>
                                        <small *ngIf="detalleNoValido" class="text-danger">Ingrese un detalle de la oferta</small>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Requisitos</mat-label>
                                        <textarea formControlName="requisitos" matInput rows="7" placeholder="Requisitos de la pre inscrpción"></textarea>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-form-field class="example-full-width" appearance="fill" [style.width.%]=100>
                                        <mat-label>Información Novedades  (visible en la aplicación móvil - coloca el texto)"</mat-label>
                                        <textarea formControlName="finalizando" matInput rows="7" placeholder="Información de la pre inscrpción"></textarea>
                                    </mat-form-field>
                                </div>

                                <div class="form-group">
                                    <mat-slide-toggle formControlName="vigente">Trámite Vigente</mat-slide-toggle>
                                </div>

                                <mat-divider></mat-divider>

                                <!-- Cartel de información -->
                                <mat-card class="info-card">
                                    <mat-card-content>
                                        <p class="text-info">
                                            <strong>Nota:</strong> Si no selecciona ningún documento, el afiliado podrá cargar hasta <strong>10 documentos</strong> a su criterio. Esto permite crear un trámite para que el afiliado adjunte la documentación que precise.
                                        </p>
                                    </mat-card-content>
                                </mat-card>
                                <br>

                                <br>


                               <!-- Toggle para "Sin documentos a solicitar" -->
                               <mat-slide-toggle
                               formControlName="sinDocumentos"
                               (change)="onToggleSinDocumentos($event)"
                               >
                               Sin documentos a solicitar
                               </mat-slide-toggle>

                                <br>

                                <mat-label>Listado de Documentación</mat-label>
                                <br>

                                <div class="form-group">
                                <mat-form-field appearance="fill" [style.width.%]=100>
                                    <mat-label>Documentos a solicitar</mat-label>
                                    <mat-select 
                                        formControlName="documentosEstadosSolicitar" 
                                        tabindex="5" 
                                        multiple 
                                        [disabled]="sinDocumentos" 
                                        (selectionChange)="onDocumentoSeleccionado($event)"
                                        [class.is-invalid]="documentosEstadosSolicitar"
                                    >                                        
                                        <mat-option *ngFor="let item of adjuntos" [value]="item._id">
                                            {{ item.nombre }}
                                        </mat-option>
                                    </mat-select>
                                    <small *ngIf="documentosEstadosSolicitarNoValido" class="text-danger">
                                        Seleccione un documento disponible
                                    </small>
                                </mat-form-field>
                                </div>                            
                                
                                <!-- Sección para mostrar documentos ya seleccionados -->
                                <mat-card *ngIf="!this.actividad?.documentosEstadosSolicitar?.length > 0" class="animate__animated animate__fadeIn">
                                    <mat-card-title>No solicita documentos al afiliado</mat-card-title>
                                    <mat-card-subtitle>El afiliado puede cargar un maximo de 10 documentos según disponga</mat-card-subtitle>
                                    <mat-card-content>
                                        <h5 *ngFor="let item of this.actividad?.documentosEstadosSolicitar">{{ item.documento?.nombre }}</h5>
                                    </mat-card-content>
                                </mat-card>
                                <mat-card *ngIf="this.actividad?.documentosEstadosSolicitar?.length > 0 && !form.get('sinDocumentos').value" class="animate__animated animate__fadeIn">
                                <!-- <mat-card *ngIf="this.actividad?.documentosEstadosSolicitar?.length > 0 " class="animate__animated animate__fadeIn"> -->
                                    <mat-card-title>Documentos a solicitar</mat-card-title>
                                    <mat-card-subtitle>Para modificar los documentos a solicitar, debe seleccionar nuevamente todos los documentos. Actualmente tiene configurado:</mat-card-subtitle>
                                    <mat-card-content>
                                        <h5 *ngFor="let item of this.actividad?.documentosEstadosSolicitar">{{ item.documento?.nombre }}</h5>
                                    </mat-card-content>
                                </mat-card>

                                <!-- <mat-label> Listado de Documentación</mat-label>
                                <div class="form-group">
                                    <mat-form-field appearance="fill" [style.width.%]=100>
                                        <mat-label>Documentos a solicitar</mat-label>
                                        <mat-select formControlName="documentosEstadosSolicitar" tabindex="5" multiple [class.is-invalid]="documentosEstadosSolicitar">
                                            <mat-option *ngFor="let item of adjuntos" value="{{ item._id }}">{{ item.nombre }}</mat-option>
                                        </mat-select>
                                        <small *ngIf="documentosEstadosSolicitarNoValido" class="text-danger">Seleccione un documento disponible</small>
                                    </mat-form-field>
                                    <mat-card *ngIf="this.actividad?.documentosEstadosSolicitar?.length > 0" class="animate__animated animate__fadeIn">
                                        <mat-card-title>Documentos solicitar</mat-card-title>
                                        <mat-card-subtitle>para modificar los socumentos a solicitar, debe seleccionar nuevamente todos los documentos, actualmente tiene configurado</mat-card-subtitle>
                                        <mat-card-content>
                                            <h5 *ngFor="let item of this.actividad?.documentosEstadosSolicitar">{{ item.documento.nombre }} </h5>
                                        </mat-card-content>
                                    </mat-card>
                                </div> -->

                            </div>

                        </div>
                        <app-loading *ngIf="flagProcesando" class="m-5"></app-loading><br>
                        <button [disabled]="!form.valid || this.cargandoArchivo" type="submit" disabled>Guardar</button>
                        <a routerLink="/tramites-configuracion"> <button type="button">Cancelar</button></a>
                    </form>
                    <div *ngIf="this.cargandoArchivo">
                        <div class="progress" style="height: 20px;">
                            <div class="progress-bar bg-info" role="progressbar" [style.width]="this.value + '%'" aria-valuenow="this.value" aria-valuemin="0" aria-valuemax="100">{{this.value}}%</div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>