<div class="appstore-badges-container">
    <div class="text-container">
        <p class="download-invite">Si ya ha registrado su usuario, le invitamos a descargar nuestra aplicación móvil.</p>
    </div>
    <div class="images-container">
      <!-- Mostrar ambos badges si no es Android/iOS -->
      <a *ngIf="isAndroid || (!isAndroid && !isIOS)" 
         (click)="redirectTo('android')" href="#">
        <img class="google-badge" src="assets/img/appstore-badges/google-play-badge.png" alt="Google Play Badge">
      </a>
      
      <a *ngIf="isIOS || (!isAndroid && !isIOS)" 
         (click)="redirectTo('ios')" href="#">
        <img class="ios-badge" src="assets/img/appstore-badges/Download_on_the_App_Store_Badge_ES_RGB_blk_100217.svg" alt="App Store Badge">
      </a>
    </div>
</div>
  
