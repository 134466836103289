import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackButtonDirective } from './Directives/back-button.directive';

// J.C.RAMOS
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './modulos/dashboard.module';
import { HomeModule } from './modulos/home.module';
import { TemplateModule } from './modulos/template.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CursosModule } from './modulos/cursos.module';
import { OfertasLaboralesModule } from './modulos/ofertas-laborales.module';
import { Entidades } from './modulos/entidades.module';
import { CommonComponentsModule } from './modulos/common.components.module';

// Locale
import localePy from '@angular/common/locales/es-PY';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeEsAr from '@angular/common/locales/es-AR';
import { registerLocaleData, DatePipe } from '@angular/common';

// Variables Globales
import { AppOfertas, APP_CONFIG_OFERTAS } from './app.config/app.config-ofertas';
import { APP_CONFIG_CURSOS, AppCursos } from './app.config/app.config-cursos';
import { AppConfig, APP_CONFIG } from './app.config/app.config';
import { APP_CONFIG_SHARED, AppShared } from './app.config/app.config-shared';
import { TranslatePipe } from './pipes/translate.pipe';
import { PipesModule } from './modulos/pipes.module';
import { FechaPickerPipe } from './pipes/ofertas-laborales/fecha-picker.pipe'
import { TidioChatComponent } from './tidio-chat/tidio-chat.component';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';

registerLocaleData(localePy, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent,
    BackButtonDirective,
    TidioChatComponent,
  ],
  imports: [
    BrowserModule,
    AuthModule, // Modulo seguridad J.C.RAMOS
    TemplateModule,
    DashboardModule,
    HomeModule,
    CursosModule,
    OfertasLaboralesModule,
    Entidades,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonComponentsModule,
    PipesModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-Ar' } ,
    { provide: APP_CONFIG, useValue: AppConfig } ,
    { provide: APP_CONFIG_OFERTAS, useValue: AppOfertas } ,
    { provide: APP_CONFIG_CURSOS, useValue: AppCursos } ,
    { provide: APP_CONFIG_SHARED, useValue: AppShared } ,
    
    /* Proveeo los pipes , para poder usar en .ts*/
    DatePipe,
    TranslatePipe,
    FechaPickerPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


/* ############################## Config angular.json ############################## */

// https://stackoverflow.com/questions/65537399/angular-11-stuck-on-generating-browser-application-bundles-phase-building
/*
1-In architect > build > configuration section add:
"development": {
  "buildOptimizer": false,
  "optimization": false,
  "vendorChunk": true,
  "extractLicenses": false,
  "sourceMap": true,
  "namedChunks": true
},
2-In architect > build
"defaultConfiguration": "production"
3-In architect > serve > configuration section add:
"development": {
  "browserTarget": "{{PROJECT_NAME}}:build:development"
 }
4-In architect > buildNote: You should put the actual project name in the place of {{PROJECT_NAME}} e.g. "browserTarget": "my-project:build:development"
5-In architect > serve section add:
"defaultConfiguration": "development"

/* ############################## FIN Config angular.json ############################## */


/* ############################## Versiones instaladas compatibles Modules ############################## */
/* <- ng2-charts -> */
// https://www.chartjs.org/docs/latest/getting-started/installation.html
// https://cdnjs.com/libraries/Chart.js
// npm install chart.js@2.9.3 --save
// npm install ng2-charts@2.2.3 --save

/*  <-GalleryModule-> */
// https://www.npmjs.com/package/@ks89/angular-modal-gallery#microphone-live-demo-microphone
// import { GalleryModule } from '@ks89/angular-modal-gallery';

/*  <-ngx-captcha-> */
// https://www.npmjs.com/package/ngx-captcha


// INSTALAR DATETIME PICKER PARA LA VERSION 11 DE ANGULAR
/* primero : npm install @angular-material-components/datetime-picker@5.x */
/* segundo : npm install --save  @angular-material-components/moment-adapter@5.x */ 
// https://www.npmjs.com/package/@angular-material-components/datetime-picker
/* ############################## FIN Versiones instaladas compatibles Modules ############################## */

/* ############################## Paypal count test ############################## */

// Email ID:
// sb-53uvq7678776@personal.example.com
// System Generated Password:
// 6k62N?.0

